\:root

  .icon-9,
  .mat-icon.icon-9,
  .mat-step-icon .mat-icon.icon-9
    font-size: 9px
    width: 9px
    height: 9px

  .icon-14,
  .mat-icon.icon-14
    font-size: 14px
    width: 14px
    height: 14px

  .icon-18,
  .mat-icon.icon-18
    font-size: 18px
    width: 18px
    height: 18px

  .icon-20,
  .mat-icon.icon-20
    font-size: 20px
    width: 20px
    height: 20px

  .icon-30,
  .mat-icon.icon-30
    font-size: 30px
    width: 30px
    height: 30px

  .icon-36,
  .mat-icon.icon-36
    font-size: 36px
    width: 36px
    height: 36px

  .icon-48,
  .mat-icon.icon-48
    font-size: 48px
    width: 48px
    height: 48px

  .icon-56,
  .mat-icon.icon-56
    font-size: 56px
    width: 56px
    height: 56px

  .icon-72,
  .mat-icon.icon-72
    font-size: 72px
    width: 72px
    height: 72px

  .icon-100,
  .mat-icon.icon-100
    font-size: 100px
    width: 100px
    height: 100px
