\:root
  .mat-mdc-dialog-container
    .mdc-dialog__surface
      border-radius: 8px
      .mdc-dialog__title
        font-size: 1.13em
        font-weight: 600
        letter-spacing: normal
        color: var(--gunmetal)
      .mdc-dialog__content
        letter-spacing: normal

  /* Modal with out vessels on the map */
  &.cdk-global-scrollblock:has(.wo-vessels-wrap)
    position: initial
    overflow-y: hidden
  .wo-vessels-wrap
    #viewDiv::after
      content: ''
      background: var(--gunmetal-025)
      backdrop-filter: blur(2px)
      position: fixed
      width: 100%
      height: 100%
    .cdk-overlay-container
      position: absolute
      min-width: var(--html-logged-in-width)
