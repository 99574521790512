// Custom Filters in grids
.mat-accordion.customFiltersWrap
  --mat-expansion-container-background-color: transparentize()
  .mat-expansion-panel
    box-shadow: none
    .mat-expansion-panel-body
      display: flex
      flex-direction: row
      gap: 12px
      padding: 5px 0 0 0

// Accordion to assign tons to companies in the shipments grid view
.dataList
  .mat-expansion-panel:not([class*='mat-elevation-z'])
    box-shadow: none
  .mat-expansion-panel-body
    padding: 0
    div[class^="col"]
      box-sizing: content-box
      max-width: 200px
      &.status-wrap
        position: relative
        svg.status-bar
          position: absolute
          overflow: visible
          left: 20px
          top: 0
          line.status-line
            stroke: var(--light-silver)
            stroke-width: 1px
            &.status-line-completed
              stroke: var(--green-status)
          circle.circle
            fill: var(--light-silver)
            &.status-circle-completed
              fill: var(--green-status)
          circle.stroke-f-circle
            fill: var(--white)
            stroke: var(--light-silver)
            stroke-width: 1px

