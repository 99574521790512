#mapStepper
  --mat-stepper-header-height: 48px
  --mat-stepper-header-label-text-size: 14px
  --mat-stepper-header-label-text-color: var(--gunmetal)
  --mat-stepper-header-selected-state-label-text-size: 14px
  transition: opacity 0.5s ease-in-out
  &.mat-stepper-hidden
    opacity: 0
    margin-top: -500px
  &.mat-stepper-visible
    opacity: 1
  .mat-stepper-horizontal
    background: var(--white-055)
    backdrop-filter: blur(2px)
    border-radius: 8px
  .mat-horizontal-stepper-header
    .mat-step-icon
      z-index: 2
      background: transparent
      color: var(--primary-blue)
      &.mat-step-icon-selected,
      &.mat-step-icon-state-edit
        background: var(--primary-blue)
        color: var(--white)
    .mat-step-label
      padding-top: 6px
      p
        font-size: 12px
    &::before, &::after
      border-top: 2px solid var(--primary-blue)
      width: calc(50% + 1px)
      z-index: 1
  .mat-horizontal-content-container
    display: none
