\:root
  body

    .white
      color: var(--white)

    .silver
      color: var(--silver)

    .cool-grey
      color: var(--cool-grey)

    .gunmetal
      color: var(--gunmetal)

    .primary-green
      color: var(--primary-green)

    .secondary-green
      color: var(--secondary-green)

    .primary-blue
      color: var(--primary-blue)


    // Status colors
    .green-status
      color: var(--green-status)

    .red-status
      color: var(--red-status)

    .yellow-status
      color: var(--yellow-status)
