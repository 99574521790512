\:root

  .customFiltersWrap
    --mat-datepicker-range-input-separator-color: var(--cool-grey)
    .mat-mdc-form-field
      max-width: calc((100% - 590px) / 3) // TODO: Modify this line when the filters are used in several grids
    .mat-mdc-form-field:not(:has(.mat-mdc-select-empty))
      &:has(.mdc-notched-outline--notched)
        .mdc-text-field--outlined
          background: var(--secondary-green)
          .mat-date-range-input-inner,
          .mat-date-range-input-separator,
          .mat-datepicker-toggle,
          .mat-mdc-select,
          .mat-mdc-select-arrow,
          .mat-form-field-label
            color: var(--white)
          .mat-date-range-input-inner
            &::placeholder
              transition: none
              color: var(--white)
              opacity: 1
          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing
            border: none
      &.mat-form-field-hide-placeholder
        .mdc-text-field--outlined
          background: var(--white)
    .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
    .mat-button-toggle-group-appearance-standard
      --mat-standard-button-toggle-height: 35px
      --mat-standard-button-toggle-shape: 8px
      --mat-standard-button-toggle-text-color: var(--black-060)
      --mat-standard-button-toggle-selected-state-text-color: var(--white)
      --mat-standard-button-toggle-selected-state-background-color: var(--secondary-green)
      border-color: var(--silver)
      font-size: 16px
    .mat-date-range-input
      .mat-date-range-input-inner
        cursor: pointer
