\:root

  // COLOR VARIABLES

  --white: #fff
  --white-015: rgba(255, 255, 255, 0.15)
  --white-055: rgba(255, 255, 255, 0.55)
  --white-080: rgba(255, 255, 255, 0.80)
  --black: #000000
  --black-016: rgba(0, 0, 0, 0.16)
  --black-050: rgba(0, 0, 0, 0.50)
  --black-060: rgba(0, 0, 0, 0.60)

  // Grey range
  --light-grey: #f7f8f8
  --pale-grey: #eaf3f5
  --light-silver: #e4eaea
  --silver: #c4cbd3
  --cool-grey: #97aca9
  --battleship-grey: #707787
  --gunmetal: #495752
  --gunmetal-050: rgba(73, 87, 82, 0.50)
  --gunmetal-025: rgba(73, 87, 82, 0.25)

  // Green range
  --primary-green: rgba(58, 93, 38, 1)
  --primary-green-070: rgba(58, 93, 38, 0.70)
  --primary-green-045: rgba(58, 93, 38, 0.45)
  --primary-green-025: rgba(58, 93, 38, 0.25)

  --secondary-green: rgba(43, 168, 74, 1)
  --secondary-green-015: rgba(43, 168, 74, 0.15)
  --secondary-green-010: rgba(43, 168, 74, 0.1)

  --tertiary-green: rgba(23, 64, 0, 1)

  // Blue range
  --primary-blue: #2669FF

  // Yellow range
  --dull-yellow: #E2CA53

  // Gradients
  --primary-green-gradient-bg: linear-gradient(0deg, var(--primary-green-045) 0%, var(--primary-green) 100%)
  --mixed-green-gradient-bg: linear-gradient(90deg, var(--secondary-green) 0%, var(--tertiary-green) 100%)
  --radial-gradient-bg: radial-gradient(circle at -10% -10%, var(--secondary-green), transparent 50vh), radial-gradient(circle at -15% 115%, var(--secondary-green), transparent 50vh), radial-gradient(circle at 105% 105%, var(--secondary-green), var(--tertiary-green) 50vw)

  // Color Status
  --green-status: #43bf65
  --green-status-010: rgba(67, 191, 101, 0.10)
  --red-status: #e25353
  --red-status_alt: #ff8a80
  --red-status-010: rgba(226, 83, 83, 0.10)
  --yellow-status: #ebba15
  --yellow-status-010: rgba(235, 186, 21, 0.10)

  // SIZES VARIABLES
  --header-height: 60px
  --html-height: 720px
  --html-logged-out-width: 438px
  --html-logged-in-width: 1200px
  --section-padding-y: 1em
  --section-padding-x: 2em
