/* For use in src/lib/core/theming/_palette.scss */

$agd-primary: (
  50 : #e5edff,
  100 : #bed2ff,
  200 : #93b4ff,
  300 : #6796ff,
  400 : #4780ff,
  500 : #2669ff,
  600 : #2261ff,
  700 : #1c56ff,
  800 : #174cff,
  900 : #0d3bff,
  A100 : #ffffff,
  A200 : #f6f8ff,
  A400 : #c3cdff,
  A700 : #aab7ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$agd-accent: (
  50 : #e6f5e9,
  100 : #bfe5c9,
  200 : #95d4a5,
  300 : #6bc280,
  400 : #4bb565,
  500 : #2ba84a,
  600 : #26a043,
  700 : #20973a,
  800 : #1a8d32,
  900 : #107d22,
  A100 : #b0ffbb,
  A200 : #7dff8f,
  A400 : #4aff63,
  A700 : #30ff4c,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ),
);

$agd-warn: (
  50: #fceaea,
  100: #f6cbcb,
  200: #f1a9a9,
  300: #eb8787,
  400: #e66d6d,
  500: #e25353,
  600: #df4c4c,
  700: #da4242,
  800: #d63939,
  900: #cf2929,
  A100: #ffffff,
  A200: #ffdada,
  A400: #ffa7a7,
  A700: #ff8e8e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
