/* You can add global styles to this file, and also import other style files */

@use 'assets/styles/my-palette.scss' as palette
@use '@angular/material' as mat
mat.$theme-ignore-duplication-warnings: true

// Font
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0')

// Variables
@import 'assets/styles/variables.sass'

// Breakpoints
@import 'assets/styles/breakpoints.sass'

// ArcGIS Map and Widgets
@import 'https://js.arcgis.com/4.28/esri/themes/light/main.css'
@import 'assets/styles/arcgis.scss'

// Other CSS classes
@import 'assets/styles/accordions.sass'
@import 'assets/styles/alerts.sass'
@import 'assets/styles/backgrounds.sass'
@import 'assets/styles/borders.sass'
@import 'assets/styles/buttons.sass'
@import 'assets/styles/cards.sass'
@import 'assets/styles/chips.sass'
@import 'assets/styles/colors.sass'
@import 'assets/styles/dialogs.sass'
@import 'assets/styles/filters.sass'
@import 'assets/styles/forms.sass'
@import 'assets/styles/grids.sass'
@import 'assets/styles/icons.sass'
@import 'assets/styles/lists.sass'
@import 'assets/styles/menu.sass'
@import 'assets/styles/steppers.sass'
@import 'assets/styles/tabs.sass'
@import 'assets/styles/text-fonts.sass'
@import 'assets/styles/tooltips.sass'

// CUSTOM THEMING FOR AGD SUSTAINABLE
$my-typography: mat.define-typography-config($font-family: 'Poppins')
$logged-in-section-primary: mat.define-palette(palette.$agd-primary)
$logged-in-section-accent: mat.define-palette(palette.$agd-accent)
$logged-in-section-warn: mat.define-palette(palette.$agd-warn)
$logged-in-section-theme: mat.define-light-theme((color: (primary: $logged-in-section-primary, accent: $logged-in-section-accent, warn: $logged-in-section-warn), typography: $my-typography))
@include mat.all-component-themes($logged-in-section-theme)

// Auth section theme
.auth-section-theme
  $auth-section-primary: mat.define-palette(palette.$agd-primary)
  $auth-section-primary_A100: mat.define-palette(palette.$agd-primary, A100)
  $auth-section-accent: mat.define-palette(mat.$blue-palette)
  $auth-section-accent_A100: mat.define-palette(palette.$agd-accent, A100)
  $auth-section-warn: mat.define-palette(mat.$red-palette, A100)
  $auth-section-theme: mat.define-dark-theme((color: (primary: $auth-section-primary, accent: $auth-section-accent, warn: $auth-section-warn), typography: $my-typography))
  $auth-section-theme_alt: mat.define-dark-theme((color: (primary: $auth-section-primary_A100, accent: $auth-section-accent_A100, warn: $auth-section-warn), typography: $my-typography))
  @include mat.all-component-themes($auth-section-theme)
  @include mat.form-field-theme($auth-section-theme_alt)
  @include mat.progress-spinner-theme($auth-section-theme_alt)
  box-sizing: border-box
  padding-top: 40px

html,
body
  padding: 0
  margin: 0
  background-color: var(--light-grey)
  font-family: 'Poppins', sans-serif
  min-width: var(--html-logged-in-width) // Min resolution: it's not a full responsive website yet
  & > .hasMap
    min-height: var(--html-height) // Min resolution: it's not a full responsive website yet
html,
body
  &:has(.auth-section-theme)
    min-width: var(--html-logged-out-width)
html
  &:has(mat-drawer-content)
    overflow-y: hidden
  &:has(.auth-section-theme)
    overflow-y: auto
    background: var(--radial-gradient-bg)
body
  --mat-divider-color: var(--cool-grey)
  &:has(.auth-section-theme)
    background: transparent

  .scrollbar
    scrollbar-color: var(--white) var(--white-015)
    scrollbar-width: thin
    &.bg-white,
    &.bg-light-grey
      scrollbar-color: var(--cool-grey) var(--white-015)

  .shadow_alt
    box-shadow: 0 3px 6px var(--black-016)

  .rounded-80
    border-radius: 80px

  .wo-before::before
    display: none
