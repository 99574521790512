/* ArcGis custom styles for map and widgets */

:root {
  --calcite-sans-family: 'Poppins', sans-serif;
  border-inline-start: none;
  border-inline-start-width: 0;
  border-inline-start-color: transparent;
  .calcite-mode-light {
    --calcite-color-brand: var(--white);
    --calcite-color-border-1: var(--white);
    --calcite-color-text-1: var(--gunmetal);
    --calcite-color-border-2: var(--light-grey);
  }
}

#vesselsWidget {
  width: 300px;
  overflow: hidden;
  .container--border {
    border-inline-start-width: 0;
  }
}

.esri-widget {
  font-family: var(--calcite-sans-family);
  background: var(--light-grey);
  border-radius: 8px;
  border: 0.5px solid transparent;
  &.esri-zoom {
    --calcite-color-text-1: var(--white);
    margin-bottom: -15px;
    background: var(--primary-green);
    border-radius: 8px;
    .esri-widget--button {
      background: transparent;
      color: var(--white);
      border-radius: 0;
      &:last-child {
        border-top: 0.5px solid var(--white-055);
      }
      .esri-icon-plus:before,
      .esri-icon-minus:before {
        font-family: 'Material Symbols Outlined', sans-serif;
        font-size: 20px;
      }
      .esri-icon-plus:before {
        content: '\e145';
      }
      .esri-icon-minus:before {
        content: '\e15b';
      }
    }
  }
  &.esri-attribution {
    visibility: hidden;
    z-index: -999;
  }
}

.esri-ui-bottom-left {
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.esri-swipe.esri-swipe--horizontal {
  background: transparent;
  .esri-swipe__container {
    overflow: visible;
    .esri-swipe__divider {
      border-color: var(--black-016);
      width: 5px;
      left: 15px;
      .year-before,
      .year-after {
        transition: background-color 0.5s ease-in-out;
        display: flex;
        gap: 60px;
        background: var(--white-055);
        backdrop-filter: blur(4px);
        position: absolute;
        bottom: 95px;
        width: 142px;
        height: 40px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 800;
        color: var(--gunmetal);
        text-shadow: 0 0 2px white;
        justify-content: center;
        align-items: center;
        span {
          font-size: 13px;
          font-weight: 600;
          vertical-align: text-top;
        }
      }
      .year-before {
        right: 11px;
      }
      .year-after {
        left: 10px;
      }
    }
    .esri-swipe__handle {
      border-radius: 8px;
      border-color: var(--black-016);
      height: 36px;
      .esri-icon-drag-vertical:before {
        font-family: 'Material Symbols Outlined', sans-serif;
        color: var(--cool-grey);
        font-size: 30px;
        content: '\e5d7';
        display: inline-block;
        transform: rotate(90deg);
      }
    }
    &:focus {
      .esri-swipe__divider,
      .esri-swipe__handle {
        outline-color: var(--black-016);
        outline-style: solid;
        outline-width: 0.5px;
        outline-offset: 0px;
      }
    }
    &:hover {
      .year-before,
      .year-after {
        background: var(--white-080);
      }
    }
  }
}

.map-stepper-container {
  .esri-widget {
    background: var(--white-055);
  }
}

calcite-accordion-item {
  calcite-list {
    overflow-y: auto;
    max-height: calc(50vh - 171px);
    min-height: 90px;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0 -17px -8px -17px;
    calcite-list-item {
      background: var(--white);
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      &:not(:first-child) {
        margin-block-start: 8px;
      }
      &[selected] {
        &:before {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          background-color: var(--primary-blue);
          left: 0;
          top: 0;
        }
      }
    }
  }
}

calcite-chip {
  &[appearance='solid'] {
    background: var(--pale-grey);
    color: var(--battleship-grey);
    border-radius: 4px;
  }
}
