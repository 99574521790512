.list-inside
  list-style-position: inside

.auth-section-theme
  .mat-mdc-nav-list
    .mdc-list-item
      background-color: var(--white-015)
      border-radius: 8px
      .mdc-list-item__start
        color: var(--white)
      .mdc-list-item__primary-text,
      .mdc-list-item__secondary-text
        letter-spacing: 0.1px
      &.mdc-list-item--disabled
        .mdc-list-item__start
          opacity: 0.38

\:root
  .map-widget
    .mat-mdc-list,
    .mat-mdc-nav-list
      padding-top: 0px
      padding-bottom: 0px
      .mat-mdc-list-item
        background: var(--light-grey)
        &.mdc-list-item--with-leading-icon
          &:hover
            .mdc-list-item__start
              color: var(--gunmetal)
          &.mdc-list-item--with-one-line,
          &.mdc-list-item--with-two-lines
            height: 50px
            .mdc-list-item__primary-text::before
              height: 20px
        &:not(:last-child)
          margin-bottom: 2px
        .mat-mdc-list-item-icon.align-self-center.mdc-list-item__start
          margin: 0 12px 0 17px
          &.reference-number
            font-size: 11px
            line-height: 22px
            font-weight: 600
            text-align: center
            margin: 0 10px 0 15px
            width: 24px
            height: 24px
            border-radius: 100%
            border: 0.5px solid var(--battleship-grey)
            background-color: var(--white)
        .mdc-list-item__primary-text,
        .mdc-list-item__secondary-text
          font-size: 13px
          color: var(--gunmetal)
          letter-spacing: 0.1px
    .mat-mdc-nav-list
      .mat-mdc-list-item
        .mdc-list-item__primary-text
          color: var(--primary-blue)

.mat-mdc-list,
.mat-mdc-nav-list
  .mat-mdc-list-item
    .mdc-list-item__primary-text,
    .mdc-list-item__secondary-text
      letter-spacing: 0.1px
