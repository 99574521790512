section
  .mat-mdc-tab-header
    border-bottom: 1px solid var(--primary-green-025)
    padding-bottom: 9px
    .mat-mdc-tab
      background-color: var(--white)
      border-radius: 8px
      margin-right: 0.5rem
      padding: 0 12px
      .mdc-tab__text-label
        color: var(--cool-grey)
        letter-spacing: 0
      .mdc-tab-indicator
        display: none
      &.mdc-tab--active
        background-color: var(--primary-green)
        .mdc-tab__text-label
          color: var(--white)
      &:last-child
        margin-right: 0
      &:not(.mdc-tab--stacked)
        height: 40px
