.border-inner-3-white // We use it for the images of the Vessels in the Shipments list module
  border: 3px solid var(--white-080)
  box-sizing: border-box
  position: relative
  overflow: hidden
  img
    position: absolute
    height: calc(100% + 8px)
    left: -4px
    top: -4px

.border-top-05-light-silver
  border-top: 0.5px solid var(--light-silver)

.border-y-05-light-silver
  border-top: 0.5px solid var(--light-silver)
  border-bottom: 0.5px solid var(--light-silver)
