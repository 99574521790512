\:root
  .bg-white
    background: var(--white)

  .bg-white-015
    background: var(--white-015)

  .bg-white-055
    background: var(--white-055)
    backdrop-filter: blur(4px)

  .bg-white-080
    background: var(--white-080)
    backdrop-filter: blur(4px)

  .bg-light-grey
    background: var(--light-grey)

  .bg-pale-grey
    background: var(--pale-grey)

  .bg-gunmetal
    background: var(--gunmetal)

  .bg-primary-green
    background: var(--primary-green)

  .bg-secondary-green-010
    background: var(--secondary-green-010)

  .bg-secondary-green-015
    background: var(--secondary-green-015)

  .bg-primary-blue
    background: var(--primary-blue)

  .bg-mixed-green-gradient
    background: var(--mixed-green-gradient-bg)

  // Modals and navbar background
  body
    .mat-drawer-backdrop.mat-drawer-shown,
    .cdk-overlay-dark-backdrop
      background: var(--gunmetal-050)
      backdrop-filter: blur(2px)
