\:root
  .mat-mdc-menu-panel
    min-width: 300px
    border-radius: 8px
    .mat-mdc-menu-content
      border: 0.5px solid var(--cool-grey)
    .mat-mdc-menu-content,
    .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text
      letter-spacing: 0.2px

  .notification-menu, 
  .cdk-overlay-pane 
    .mat-mdc-menu-panel 
      min-width: 390px
      max-width: 390px
      padding-bottom: 35px
      overflow-y: auto
      overflow-x: hidden
    
    .mat-mdc-menu-content
      padding-bottom: 0
    
    .unread
      background-color: var(--pale-grey)
      
    .mat-mdc-list-item 
      padding-top: 0.5em
      padding-right: 1em
      text-overflow: ellipsis
      .bold
        font-weight: bold
      .ml-1
        margin-left: 1em
      .ml-2
        margin-left: 2em
      