
.alert
  font-size: 13px
  line-height: 18px
  color: var(--cool-grey)
  background: var(--light-grey)
  border-radius: 8px
  padding: 10px 10px 10px 43px
  position: relative
  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    width: 6px
    height: 100%
    border-radius: 8px 0 0 8px
    background: var(--primary-blue)
  &:after
    font-family: 'Material Icons', sans-serif
    font-size: 22px
    color: var(--primary-blue)
    content: 'info'
    position: absolute
    left: 14px
    top: calc(50% - 10px)

  // Alert Warning
  &.alert-warning
    &:before
      background: var(--dull-yellow)
    &:after
      color: var(--dull-yellow)
      content: 'warning_amber'

