\:root

  // AUTH SECTION
  .auth-section-theme
    .mat-mdc-unelevated-button
      &.mat-primary
        font-size: 16px
        font-weight: bold
        letter-spacing: 0.5px

  // OTHERS SECTIONS
  .mat-mdc-button.flex-row-reverse
    .mat-icon
      margin-right: 0
      margin-left: 4px

  .mat-mdc-icon-button.mat-mdc-button-base
    &[disabled]
      --mdc-icon-button-disabled-icon-color: var(--silver)
    &[size='small']
      width: 30px
      height: 30px
      padding: 2px
      .mat-mdc-button-touch-target
        width: 30px
        height: 30px
      .mat-icon
        font-size: 18px
        width: 18px
        height: 18px
    &[size='medium']
      width: 32px
      height: 32px
      padding: 4px
      .mat-mdc-button-touch-target
        width: 32px
        height: 32px

  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base
    min-height: 48px
    border-radius: 8px
    letter-spacing: 0px
    font-weight: 400
    &[size]
      min-height: auto
    // Extra Small Buttons
    &[size='xsmall']
      height: 32px
      font-size: 13px
      letter-spacing: 0.5px
      .mat-icon
        font-size: 22px
        width: 22px
        height: 22px
    // Small Buttons
    &[size='small']
      height: 36px
      font-size: 14px
      .mat-icon
        font-size: 22px
        width: 22px
        height: 22px
