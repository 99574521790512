\:root

  // All Widgets
  .map-widget
    border-radius: 8px
    width: 278px
    .mat-mdc-card-content
      padding-bottom: 10px
    .map-widget-main-area
      overflow: hidden
      border-radius: 0 0 8px 8px
    &.map-widget-closed
      .mat-mdc-card-content
        padding-bottom: 0
      .map-widget-header
        border-radius: 8px
      .map-widget-main-area
        height: 0
    .map-widget-header
      display: flex
      align-items: center
      margin: -1px -1px 0 -1px
      padding: 2px 6px 2px 17px
      background: var(--primary-green)
      border-radius: 8px 8px 0 0
      color: var(--white)
      .title
        font-size: 14px
        font-weight: 600
        color: var(--white)
        display: flex
        margin: 0
    .map-widget-content
      overflow-y: auto
      min-height: 182px
      max-height: calc(50vh - 172px)
      &:has(+ .map-widget-buttons)
        min-height: 172px
      .map-widget-img-vessel
        margin: 0 auto
        overflow: hidden
        border-radius: 4px
        display: flex
        width: 160px
        height: 90px
    .map-widget-buttons
      gap: 8px
      display: flex
      justify-content: flex-end
      padding: 16px 16px 24px

  // References Widget
  #references
    .map-widget
      .map-widget-content
        min-height: 232px
