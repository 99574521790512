\:root

  #viewDiv
    height: 100vh
    min-height: var(--html-height)
    width: calc(100% + (var(--section-padding-x) * 2))
    margin: calc((var(--header-height) + var(--section-padding-y)) * -1) 0 calc(var(--section-padding-y) * -1) calc(var(--section-padding-x) * -1)
    padding: 0

    .mapLoading
      transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out
      opacity: 0
      background: var(--white-055)
      backdrop-filter: blur(2px)
      border-radius: 8px
      visibility: hidden
      position: absolute
      display: inline-flex
      width: fit-content
      bottom: -33px
      left: 0
      right: 0
      margin: 0 auto
    &.loading-map
      .mapLoading
        opacity: 1
        visibility: visible

  body.wo-vessels-wrap
    #viewDiv
      &.loading-map
        .mapLoading
          display: none

  .vh-100-w-header
    min-height: calc(100vh - var(--header-height))
    box-sizing: border-box

  .vh-50
    height: 50vh

  .mw-none
    max-width: none!important

  .mw-300
    max-width: 300px

  .mw-360
    width: 100%
    max-width: 360px

  .mw-600
    max-width: 600px

  .mw-1300
    max-width: 1300px

  .mh-400
    max-height: 400px

  .min-w-360
    min-width: 360px

  .min-h-50
    min-height: 50px

  .ms-25p
    margin-left: 25%

  .mt-11
    margin-top: 11px

  .mt-130
    margin-top: 130px

  .mt-n10
    margin-top: -10px

  .mb-n50
    margin-bottom: -50px

  .border-box
    box-sizing: border-box

  .ps-44p
    padding-left: 44px

  .dataList
    p
      margin: 0
    .mat-sort-header:not(.text-start)
      .mat-sort-header-container
        padding-left: 18px
        justify-content: center
    .mat-mdc-paginator
      background: none

  mat-drawer-content
    section
      article
        padding-top: 2em
